import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    IconButton,
    FormControl,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
    Snackbar,
    Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { registerEmployee } from 'contexts/api';
import { PaymentStructure } from 'types/api.types';

interface RegisterEmployeeModalProps {
    open: boolean;
    onClose: () => void;
    onUserRegistered?: () => void;
}

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    roleIds: string[];
    paymentStructure: PaymentStructure
}

interface Role {
    id: string;
    label: string;
}

const initialFormData: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    roleIds: [],
    paymentStructure: {
        basePrice: 0,
        baseInstallPricePerFoot: 0,
        baseAdminPricePerFoot: 0
    }
};

const styles = {
    dialog: {
        backgroundColor: '#1e1e1e',
        color: '#ffffff'
    },
    inputField: {
        backgroundColor: '#2e2e2e',
        marginTop: '2px',
        borderRadius: 1,
        '& .MuiOutlinedInput-root': {
            height: '40px'
        },
        '& .MuiOutlinedInput-input': {
            color: '#000000',
            padding: '8px 14px',
        }
    },
    select: {
        backgroundColor: '#ffffff',
        color: '#000000',
        height: '40px',
        '& .MuiSelect-icon': {
            color: '#000000'
        },
        marginTop: '2px'
    },
    menuItem: {
        backgroundColor: '#ffffff',
        color: '#000000',
        '&:hover': {
            backgroundColor: '#f5f5f5'
        }
    },
    label: {
        marginBottom: '2px'  // Explicit pixel value
    }
};

const RegisterEmployeeModal: React.FC<RegisterEmployeeModalProps> = ({ open, onClose, onUserRegistered }) => {
    const [formData, setFormData] = React.useState<FormData>(initialFormData);

    const availableRoles: Role[] = [
        { id: 'db238d79-cf6a-485e-b476-c31d07e23952', label: 'Sales' },
        { id: 'a2e5a51f-7d14-4e6f-9b62-1f3aecc3f73d', label: 'Installer' },
        { id: '5e33a85f-7bea-475e-8b85-b03502b0e03d', label: 'Admin' }
    ];

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleRolesChange = (e: SelectChangeEvent<string[]>) => {
        const { value } = e.target;
        setFormData(prev => ({
            ...prev,
            roleIds: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const handlePaymentStructureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            paymentStructure: {
                ...prev.paymentStructure,
                [name]: parseFloat(value) || 0
            }
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const result = await registerEmployee({
                FirstName: formData.firstName,
                LastName: formData.lastName,
                Email: formData.email,
                PhoneNumber: formData.phone,
                RoleIds: formData.roleIds,
                PaymentStructure: formData.paymentStructure
            });

            if (result.success) {
                setFormData(initialFormData);
                onUserRegistered?.();
                onClose();
            } else {
                setErrorMessage(result.message);
                setSnackbarOpen(true);
            }
        } catch (err) {
            setErrorMessage('Failed to register employee');
            setSnackbarOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    React.useEffect(() => {
        if (!open) {
            setFormData(initialFormData);
        }
    }, [open]);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{ sx: styles.dialog }}
        >
            <DialogTitle>
                Employee Info
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'grid', gap: 2 }}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                            <Box>
                                <Typography variant="subtitle2" sx={styles.label}>First Name</Typography>
                                <TextField
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    value={formData.firstName}
                                    onChange={handleTextChange}
                                    placeholder="John"
                                    sx={styles.inputField}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" sx={styles.label}>Last Name</Typography>
                                <TextField
                                    name="lastName"
                                    variant="outlined"
                                    required
                                    value={formData.lastName}
                                    onChange={handleTextChange}
                                    placeholder="Doe"
                                    sx={styles.inputField}
                                />
                            </Box>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" sx={styles.label}>Email</Typography>
                            <TextField
                                name="email"
                                type="email"
                                variant="outlined"
                                required
                                value={formData.email}
                                onChange={handleTextChange}
                                placeholder="john.doe@example.com"
                                sx={styles.inputField}
                            />
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" sx={styles.label}>Phone Number</Typography>
                            <TextField
                                name="phone"
                                variant="outlined"
                                required
                                value={formData.phone}
                                onChange={handleTextChange}
                                placeholder="(555) 123-4567"
                                sx={styles.inputField}
                            />
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" sx={styles.label}>Roles</Typography>
                            <FormControl fullWidth>
                                <Select
                                    multiple
                                    value={formData.roleIds}
                                    onChange={handleRolesChange}
                                    sx={styles.select}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Select roles</em>;
                                        }
                                        return selected.join(', ');
                                    }}
                                >
                                    {availableRoles.map((role) => (
                                        <MenuItem
                                            key={role.id}
                                            value={role.id}
                                            sx={styles.menuItem}
                                        >
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                            <Box>
                                <Typography variant="subtitle2" sx={styles.label}>Base Price</Typography>
                                <TextField
                                    name="basePrice"
                                    type="number"
                                    variant="outlined"
                                    required
                                    value={formData.paymentStructure.basePrice}
                                    onChange={handlePaymentStructureChange}
                                    placeholder="0.00"
                                    sx={styles.inputField}
                                    inputProps={{ step: "0.01" }}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" sx={styles.label}>Base Install Price Per Foot</Typography>
                                <TextField
                                    name="baseInstallPricePerFoot"
                                    type="number"
                                    variant="outlined"
                                    required
                                    value={formData.paymentStructure.baseInstallPricePerFoot}
                                    onChange={handlePaymentStructureChange}
                                    placeholder="0.00"
                                    sx={styles.inputField}
                                    inputProps={{ step: "0.01" }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        sx={{
                            backgroundColor: '#ff3668',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#9c1a3b',
                            },
                        }}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Registering...' : 'Register'}
                    </Button>
                </DialogActions>
            </form>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default RegisterEmployeeModal;