import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
    IconButton
} from '@mui/material';
import { Settings, Edit } from '@mui/icons-material';
import MapSection from '../MapSection';
import AccountProgressBar from './AccountProgressBar';
import AccountModal from './AccountModal';
import SalesSettingsModal from './SalesSettingsModal';
import { Account, User, InvoiceRequest, LightAccountFormData, InvoiceLineItem } from 'types/api.types';
import { createInvoice, updateAccount } from 'contexts/api';
import InvoiceConfirmationModal from './InvoiceConfirmationModal';

interface SalesTabProps {
    accounts: Account[];
    currentAccount: Account | null;
    center: { lat: number; lng: number } | null;
    modalOpen: boolean;
    lightAccountFormData: LightAccountFormData;
    handleMapClick: (event: google.maps.MapMouseEvent) => void;
    handleAccountClick: (Account: Account | null) => void;
    handleAccountUpdate: (updatedAccount: Account) => void;
    handleDeleteAccount: (AccountId: string) => void;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAccountFormData: React.Dispatch<React.SetStateAction<LightAccountFormData>>;
    user: User;
    isNewAccount: boolean;
    onSaveNew: () => Promise<void>;
}

const SalesTab: React.FC<SalesTabProps> = ({
    accounts,
    currentAccount,
    center,
    modalOpen,
    lightAccountFormData,
    handleMapClick,
    handleAccountClick,
    handleAccountUpdate,
    handleDeleteAccount,
    setModalOpen,
    setAccountFormData,
    user,
    isNewAccount,
    onSaveNew,
}) => {
    const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
    const [mapAccounts, setMapAccounts] = useState<Account[]>([]);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
    const [invoiceError, setInvoiceError] = useState<string | null>(null);
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

    useEffect(() => {
        setMapAccounts(accounts);

        // Maintain selected account after updates
        if (currentAccount && accounts.some(acc => acc.id === currentAccount.id)) {
            setSelectedAccountId(currentAccount.id);
        } else if (selectedAccountId && !accounts.some(acc => acc.id === selectedAccountId)) {
            setSelectedAccountId(null);
            handleAccountClick(null);
        }
    }, [accounts, currentAccount, selectedAccountId, handleAccountClick]);

    const handleAccountSelect = (event: SelectChangeEvent<string>) => {
        const newSelectedId = event.target.value;
        setSelectedAccountId(newSelectedId);
        const account = accounts.find((p) => p.id === newSelectedId);
        if (account) handleAccountClick(account);
    };

    const renderAccountName = (account: Account, isMenuItem: boolean = false) => {
        const name = account.customerName?.toUpperCase() || 'Unnamed';
        const status = account.status?.toUpperCase();

        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
                height: isMenuItem ? 'auto' : '20px',
            }}>
                <Typography
                    sx={{
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flexShrink: 1,
                        color: 'text.secondary'
                    }}
                >
                    {name}
                </Typography>
                <Typography
                    sx={{
                        color: 'text.secondary',
                        ml: 1,
                        fontSize: '0.875em',
                        flexShrink: 0
                    }}
                >
                    - {status}
                </Typography>
            </Box>
        );
    };

    const handleEditClick = () => {
        if (!selectedAccountId) return;
        const account = mapAccounts.find((p) => p.id === selectedAccountId);
        console.log('Opening modal for account:', account);
        if (account) {
            setAccountFormData({
                status: account.status || '',
                customerName: account.customerName || '',
                address: account.address || '',
                feetOfLights: account.feetOfLights?.toString() || '',
                trackColor: account.trackColor || '',
                phoneNumber: account.phoneNumber || '',
                email: account.email || '',
                description: account.description || '',
                notes: account.notes || '',
                quotePerFt: account.quotePerFt || 0,
                quote: account.quote || 0,
                acceptedPerFt: account.acceptedPerFt || 0,
                acceptedAmount: account.acceptedAmount || 0,
                paid: account.paid || false,
                materialStatus: account.materialStatus || '',
                addOns: account.addOns || 0
            });
            setModalOpen(true);
        }
    };

    const handleCreateInvoice = async (lineItems: InvoiceLineItem[]) => {
        if (!selectedAccountId || !currentAccount) return;

        setIsCreatingInvoice(true);
        setInvoiceError(null);

        try {
            const totalAmount = lineItems.reduce((sum, item) => sum + (item.amount * item.quantity), 0);

            const mainDescription = lineItems.map(item =>
                `${item.quantity}x ${item.description}`
            ).join(', ');

            const invoiceRequest: InvoiceRequest = {
                accountId: selectedAccountId,
                salesRepId: user.id,
                amount: totalAmount,
                description: mainDescription,
                lineItems: lineItems,
                dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
            };

            const response = await createInvoice(invoiceRequest);
            console.log('Invoice created:', response);
            setInvoiceModalOpen(false);

        } catch (error) {
            setInvoiceError(error instanceof Error ? error.message : 'Failed to create invoice');
        } finally {
            setIsCreatingInvoice(false);
        }
    };

    const handleSave = async (accountId: string, formData: LightAccountFormData): Promise<void> => {
        if (isNewAccount) {
            await onSaveNew();
            return;
        }

        try {
            const updatedAccount = await updateAccount(accountId, formData);
            handleAccountUpdate(updatedAccount);

            // Update local state to reflect changes immediately
            setMapAccounts(prevAccounts =>
                prevAccounts.map(acc =>
                    acc.id === accountId ? updatedAccount : acc
                )
            );

            setModalOpen(false);
        } catch (error) {
            console.error('Error updating account:', error);
            throw error;
        }
    };

    return (
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
            {/* Top Section: User Email and Settings Button */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: { xs: 1, sm: 2 } }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
                    My Accounts
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={() => {
                            setAccountFormData({
                                status: 'Pending',
                                customerName: '',
                                address: '',
                                feetOfLights: '',
                                trackColor: '',
                                phoneNumber: '',
                                notes: '',
                                email: '',
                                description: '',
                                quotePerFt: 0,
                                quote: 0,
                                acceptedPerFt: 0,
                                acceptedAmount: 0,
                                paid: false,
                                materialStatus: '',
                                addOns: 0
                            });
                            setModalOpen(true);
                            handleAccountClick(null);
                        }}
                        sx={{
                            color: '#fff',
                            p: 0,
                            width: 36,
                            height: 36,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', lineHeight: 1 }}>
                            +
                        </Typography>
                    </IconButton>
                    <IconButton
                        onClick={() => setSettingsOpen(true)}
                        sx={{
                            bgcolor: '#252423',
                            color: '#fff',
                            borderRadius: '50%',
                            width: 36,
                            height: 36,
                            ml: 0,
                            '&:hover': { bgcolor: '#333' },
                        }}
                    >
                        <Settings />
                    </IconButton>
                </Box>
            </Box>

            {/* Statistics Section */}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: { xs: 'repeat(2, min-content)', sm: 'repeat(4, min-content)' },
                gap: { xs: 1, sm: 2 },
                mb: { xs: 2, sm: 3 },
                justifyContent: 'center'
            }}>
                <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, minWidth: { xs: '110px', sm: '140px' }, textAlign: 'center' }}>
                    <Typography variant="subtitle2">In Progress</Typography>
                    <Typography variant="h6">
                        {accounts.filter(account => account.status !== 'Installed' && account.status !== 'Complete').length}
                    </Typography>
                </Paper>
                <Paper elevation={2} sx={{
                    p: { xs: 1, sm: 2 },
                    minWidth: { xs: '110px', sm: '140px' },
                    bgcolor: '#4caf50',
                    color: '#ffffff',
                    textAlign: 'center'
                }}>
                    <Typography variant="subtitle2">On The Table</Typography>
                    <Typography variant="h6">
                        ${user.totalSalesAccepted.toFixed(0)}
                    </Typography>
                </Paper>
                <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, minWidth: { xs: '110px', sm: '140px' }, textAlign: 'center' }}>
                    <Typography variant="subtitle2">Total Accounts</Typography>
                    <Typography variant="h6">
                        {user.ownedAccounts.length}
                    </Typography>
                </Paper>
                <Paper elevation={2} sx={{
                    p: { xs: 1, sm: 2 },
                    minWidth: { xs: '110px', sm: '140px' },
                    bgcolor: '#4caf50',
                    color: '#ffffff',
                    textAlign: 'center'
                }}>
                    <Typography variant="subtitle2">Paid Out</Typography>
                    <Typography variant="h6">
                        ${user.salesEarned.toFixed(0)}
                    </Typography>
                </Paper>
            </Box>

            {/* Dropdown Selector Section */}
            <Box sx={{ display: 'flex', gap: 1, mb: { xs: 2, sm: 3 } }}>
                <Select
                    value={selectedAccountId || ''}
                    onChange={handleAccountSelect}
                    fullWidth
                    size="small"
                    displayEmpty
                    renderValue={(value) => {
                        if (!value) return "Select an Account";
                        const account = accounts.find(acc => acc.id === value);
                        if (!account) return "Select an Account";
                        return renderAccountName(account);
                    }}
                    sx={{
                        bgcolor: '#ffffff',
                        backgroundColor: '#ffffff',
                        height: '36px',
                        maxWidth: 'calc(100% - 90px)', // Reserve space for buttons (36px + 36px + gap)
                        '& .MuiSelect-select': {
                            py: 0,
                            height: '36px',
                            display: 'flex',
                            alignItems: 'center',
                            px: { xs: 1, sm: 1.5 },
                            color: 'text.primary',
                            bgcolor: '#ffffff',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        },
                        '& .MuiSelect-icon': {
                            color: 'text.primary'
                        },
                        '&:hover': {
                            bgcolor: '#ffffff',
                        },
                        '&.Mui-focused': {
                            bgcolor: '#ffffff',
                        }
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: '50vh',
                                width: 'auto',
                                maxWidth: '95vw',
                                bgcolor: '#ffffff',
                            }
                        }
                    }}
                >
                    <MenuItem
                        value=""
                        disabled
                        sx={{
                            color: 'primary.main',
                            '&.Mui-disabled': {
                                color: 'primary.main'
                            }
                        }}
                    >
                        Select an Account
                    </MenuItem>
                    {accounts.map((account) => (
                        <MenuItem
                            key={account.id}
                            value={account.id}
                            sx={{
                                py: 1,
                                px: { xs: 1, sm: 1.5 }
                            }}
                        >
                            {renderAccountName(account, true)}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    variant="contained"
                    onClick={handleEditClick}
                    disabled={!selectedAccountId}
                    sx={{
                        minWidth: 36,
                        width: 36,
                        height: 36,
                        p: 0
                    }}
                >
                    <Edit sx={{ fontSize: 20 }} />
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setInvoiceModalOpen(true)}
                    disabled={!selectedAccountId || isCreatingInvoice || !currentAccount?.acceptedAmount}
                    sx={{
                        minWidth: 36,
                        width: 36,
                        height: 36,
                        p: 0,
                        bgcolor: '#4caf50',
                        '&:hover': {
                            bgcolor: 'success.dark',
                        }
                    }}
                >
                    <Box component="span" sx={{ fontSize: 20 }}>$</Box>
                </Button>
            </Box>

            {invoiceError && (
                <Typography
                    color="error"
                    sx={{ mt: 2, mb: 2 }}
                >
                    {invoiceError}
                </Typography>
            )}

            {/* Map Section */}
            <Box sx={{
                height: { xs: '300px', sm: '400px' },
                width: '100%',
                mb: { xs: 2, sm: 3 },
                border: '2px solid #1d85b5',
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative'
            }}>
                <MapSection
                    pins={accounts}
                    center={center || { lat: 40.7128, lng: -74.006 }}
                    onMapClick={handleMapClick}
                    onMarkerClick={handleAccountClick}
                />
            </Box>

            {/* Account Progress Bar */}
            <Box sx={{
                mb: { xs: 2, sm: 3 },
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden' // Prevent horizontal overflow
            }}>
                <Box sx={{
                    width: '100%',
                    maxWidth: '100%', // Ensure it doesn't exceed container width
                    px: { xs: 1, sm: 2 } // Add padding on smaller screens
                }}>
                    <AccountProgressBar currentAccount={currentAccount || {}} />
                </Box>
            </Box>

            {/* Modals */}
            {modalOpen && (
                <AccountModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={() => handleSave(selectedAccountId!, lightAccountFormData)}
                    formData={lightAccountFormData}
                    setFormData={setAccountFormData}
                    isNewAccount={isNewAccount}
                    paymentStatus={currentAccount?.paymentStatus}
                    employeePaymentStatus={currentAccount?.employeePaymentStatus}
                />
            )}

            <SalesSettingsModal
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                onSave={() => setSettingsOpen(false)}
            />

            <InvoiceConfirmationModal
                open={invoiceModalOpen}
                onClose={() => {
                    setInvoiceModalOpen(false);
                    setInvoiceError(null);
                }}
                onConfirm={handleCreateInvoice}
                account={currentAccount}
                isLoading={isCreatingInvoice}
                error={invoiceError}
            />
        </Box>
    );
};

export default SalesTab;