import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tab,
    Tabs,
    Box,
    TextField,
    MenuItem,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    CircularProgress,
    Stack,
    IconButton
} from '@mui/material';

import inventoryService from '../../contexts/inventory.service';
import { InventoryMovement, InventorySummary, InventoryQuantities } from '../../types/api.types';

interface ManageInventoryModalProps {
    open: boolean;
    onClose: () => void;
}

type MovementType = 'Ordered' | 'Received' | 'Assigned' | 'Unassigned' | 'Installed' | 'Returned' | 'Damaged' | 'Adjustment';

const MOVEMENT_TYPES: MovementType[] = [
    'Ordered',
    'Received',
    'Assigned',
    'Unassigned',
    'Installed',
    'Returned',
    'Damaged',
    'Adjustment'
];

const MOVEMENT_TYPE_MAP: Record<number, MovementType> = {
    0: 'Ordered',
    1: 'Received',
    2: 'Assigned',
    3: 'Unassigned',
    4: 'Installed',
    5: 'Returned',
    6: 'Damaged',
    7: 'Adjustment'
};

const ManageInventoryModal: React.FC<ManageInventoryModalProps> = ({ open, onClose }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [inventorySummary, setInventorySummary] = useState<InventorySummary | null>(null);
    const [movement, setMovement] = useState<Partial<InventoryMovement>>({
        inventoryId: '69643b19-621b-4f64-8a22-8026e9096e4a',
        type: 'Ordered' as MovementType,
        timestamp: new Date(),
    });

    const loadInventorySummary = async () => {
        try {
            setIsLoading(true);
            const summary = await inventoryService.getInventorySummary('69643b19-621b-4f64-8a22-8026e9096e4a');
            setInventorySummary(summary);
        } catch (err) {
            setError('Failed to load inventory summary');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            loadInventorySummary();
        }
    }, [open]);

    const handleMovementSubmit = async () => {
        try {
            setIsLoading(true);
            await inventoryService.processInventoryMovement(movement as InventoryMovement);
            await loadInventorySummary();
            setMovement({ type: 'Ordered', timestamp: new Date() });
        } catch (err) {
            setError('Failed to process movement');
        } finally {
            setIsLoading(false);
        }
    };

    const calculateFootage = (numberOfLights: number) => {
        const metersPerFiveLights = 1;
        const meters = (numberOfLights / 5) * metersPerFiveLights;
        const feet = meters * 3.28084; // Convert meters to feet
        return feet.toFixed(1);
    };

    const renderQuantitiesSummary = (quantities: InventoryQuantities) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2
            }}>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        24V Lights
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.feetOfLights24V}
                    </Typography>
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.5)">
                        {calculateFootage(quantities.feetOfLights24V)}ft
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        48V Lights
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.feetOfLights48V}
                    </Typography>
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.5)">
                        {calculateFootage(quantities.feetOfLights48V)}ft
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        24V Boxes
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.boxes24V}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        48V Boxes
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.boxes48V}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        24V White Track
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.whiteTrack24V}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        48V White Track
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.whiteTrack48V}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        24V Black Track
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.blackTrack24V}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 1 }}>
                    <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                        48V Black Track
                    </Typography>
                    <Typography variant="h6" color="#ffffff">
                        {quantities.blackTrack48V}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    backgroundColor: '#323130',
                    color: '#ffffff',
                    margin: { xs: 1, sm: 2 },
                    width: { xs: 'calc(100% - 16px)', sm: 'auto' },
                    maxHeight: { xs: '95vh', sm: '90vh' },
                }
            }}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Manage Inventory
                    <IconButton
                        onClick={onClose}
                        sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent sx={{
                minWidth: { xs: '300px', sm: '600px', md: '800px' },
                height: { xs: '500px', sm: '600px', md: '700px' },
                overflow: 'hidden'
            }}>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        sx={{
                            '& .MuiTab-root': {
                                color: 'rgba(255, 255, 255, 0.7)',
                                minWidth: { xs: '33%', sm: 'auto' },
                                padding: { xs: '12px 8px', sm: '12px 16px' },
                                fontSize: { xs: '0.8rem', sm: '0.875rem' }
                            },
                            '& .Mui-selected': {
                                color: '#ffffff',
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#4CAF50',
                            }
                        }}
                    >
                        <Tab label="Summary" />
                        <Tab label="New Movement" />
                        <Tab label="Movement History" />
                    </Tabs>

                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

                    <Box sx={{
                        mt: 2,
                        flex: 1,
                        overflow: 'auto',
                        width: '100%'
                    }}>
                        {activeTab === 0 && inventorySummary && (
                            <Stack spacing={2}>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        sm: 'repeat(2, 1fr)',
                                    },
                                    gap: { xs: 1, sm: 2 }
                                }}>
                                    {/* Available */}
                                    <Box sx={{
                                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                                        p: { xs: 1.5, sm: 2 },
                                        borderRadius: 1,
                                        border: '1px solid rgba(76, 175, 80, 0.2)'
                                    }}>
                                        <Typography
                                            variant="subtitle2"
                                            color="rgba(255, 255, 255, 0.7)"
                                            gutterBottom
                                            sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                                        >
                                            Available
                                        </Typography>
                                        {renderQuantitiesSummary(inventorySummary.available)}
                                    </Box>

                                    {/* Ordered */}
                                    <Box sx={{
                                        backgroundColor: 'rgba(33, 150, 243, 0.1)',
                                        p: { xs: 1.5, sm: 2 },
                                        borderRadius: 1,
                                        border: '1px solid rgba(33, 150, 243, 0.2)'
                                    }}>
                                        <Typography
                                            variant="subtitle2"
                                            color="rgba(255, 255, 255, 0.7)"
                                            gutterBottom
                                            sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                                        >
                                            Ordered
                                        </Typography>
                                        {renderQuantitiesSummary(inventorySummary.ordered)}
                                    </Box>

                                    {/* In Hand */}
                                    <Box sx={{
                                        backgroundColor: 'rgba(255, 193, 7, 0.1)',
                                        p: { xs: 1.5, sm: 2 },
                                        borderRadius: 1,
                                        border: '1px solid rgba(255, 193, 7, 0.2)'
                                    }}>
                                        <Typography
                                            variant="subtitle2"
                                            color="rgba(255, 255, 255, 0.7)"
                                            gutterBottom
                                            sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                                        >
                                            In Hand
                                        </Typography>
                                        {renderQuantitiesSummary(inventorySummary.inHand)}
                                    </Box>

                                    {/* Damaged */}
                                    <Box sx={{
                                        backgroundColor: 'rgba(244, 67, 54, 0.1)',
                                        p: { xs: 1.5, sm: 2 },
                                        borderRadius: 1,
                                        border: '1px solid rgba(244, 67, 54, 0.2)'
                                    }}>
                                        <Typography
                                            variant="subtitle2"
                                            color="rgba(255, 255, 255, 0.7)"
                                            gutterBottom
                                            sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
                                        >
                                            Damaged
                                        </Typography>
                                        {renderQuantitiesSummary(inventorySummary.damaged)}
                                    </Box>
                                </Box>
                            </Stack>
                        )}

                        {activeTab === 1 && (
                            <Box sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                p: { xs: 1.5, sm: 2 },
                                borderRadius: 1
                            }}>
                                <Stack spacing={{ xs: 1, sm: 2 }}>
                                    {/* Movement Type Field */}
                                    <Box>
                                        <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                            Movement Type
                                        </Typography>
                                        <TextField
                                            select
                                            fullWidth
                                            value={movement.type}
                                            onChange={(e) => setMovement({ ...movement, type: e.target.value as MovementType })}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: '40px',
                                                    backgroundColor: '#ffffff',
                                                    '& fieldset': {
                                                        borderColor: '#1d85b5',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1d85b5',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#1d85b5',
                                                    }
                                                }
                                            }}
                                        >
                                            {MOVEMENT_TYPES.map((type) => (
                                                <MenuItem key={type} value={type}>{type}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>

                                    {/* First row - 24V and 48V Lights */}
                                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                24V Lights (ft)
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.feetOfLights24V || ''}
                                                onChange={(e) => setMovement({ ...movement, feetOfLights24V: parseFloat(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                48V Lights (ft)
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.feetOfLights48V || ''}
                                                onChange={(e) => setMovement({ ...movement, feetOfLights48V: parseFloat(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                
                                    {/* Boxes row */}
                                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                24V Boxes
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.boxes24V || ''}
                                                onChange={(e) => setMovement({ ...movement, boxes24V: parseInt(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                48V Boxes
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.boxes48V || ''}
                                                onChange={(e) => setMovement({ ...movement, boxes48V: parseInt(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    {/* White Track row */}
                                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                24V White Track
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.whiteTrack24V || ''}
                                                onChange={(e) => setMovement({ ...movement, whiteTrack24V: parseInt(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                48V White Track
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.whiteTrack48V || ''}
                                                onChange={(e) => setMovement({ ...movement, whiteTrack48V: parseInt(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    {/* Black Track row */}
                                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                24V Black Track
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.blackTrack24V || ''}
                                                onChange={(e) => setMovement({ ...movement, blackTrack24V: parseInt(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ flex: '1 1 calc(50% - 8px)', minWidth: '150px' }}>
                                            <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                                48V Black Track
                                            </Typography>
                                            <TextField
                                                type="number"
                                                value={movement.blackTrack48V || ''}
                                                onChange={(e) => setMovement({ ...movement, blackTrack48V: parseInt(e.target.value) })}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '40px',
                                                        backgroundColor: '#ffffff',
                                                        '& fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#1d85b5',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#1d85b5',
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    {/* Notes field */}
                                    <Box>
                                        <Typography variant="subtitle2" color="text.primary" sx={{ mb: 0.25 }}>
                                            Notes
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={movement.notes || ''}
                                            onChange={(e) => setMovement({ ...movement, notes: e.target.value })}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    backgroundColor: '#ffffff',
                                                    '& fieldset': {
                                                        borderColor: '#1d85b5',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#1d85b5',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#1d85b5',
                                                    }
                                                }
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        )}
{activeTab === 2 && inventorySummary && (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Date</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Type</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>24V Lights</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>48V Lights</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>24V Boxes</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>48V Boxes</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>24V White Track</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>48V White Track</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>24V Black Track</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>48V Black Track</TableCell>
                    <TableCell sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Notes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {inventorySummary.recentMovements.map((movement) => (
                    <TableRow key={movement.id}>
                        <TableCell sx={{ color: '#ffffff' }}>
                            {new Date(movement.timestamp).toLocaleDateString()}
                        </TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>
                            {typeof movement.type === 'number'
                                ? MOVEMENT_TYPE_MAP[movement.type]
                                : movement.type}
                        </TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.feetOfLights24V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.feetOfLights48V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.boxes24V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.boxes48V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.whiteTrack24V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.whiteTrack48V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.blackTrack24V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.blackTrack48V}</TableCell>
                        <TableCell sx={{ color: '#ffffff' }}>{movement.notes}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
)}
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                {activeTab === 1 && (
                    <Button
                        disabled={isLoading}
                        onClick={handleMovementSubmit}
                        variant="contained"
                        startIcon={isLoading ? <CircularProgress size={20} /> : null}
                        sx={{
                            backgroundColor: '#4CAF50',
                            '&:hover': {
                                backgroundColor: '#45a049'
                            }
                        }}
                    >
                        Submit Movement
                    </Button>
                )}
                <Button
                    onClick={onClose}
                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManageInventoryModal;