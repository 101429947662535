import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Box, 
  Avatar, 
  Tooltip, 
  Modal, 
  Button,
  Paper,
  TextField,
  Alert,
  Collapse
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../assets/logo3.png';
import { changePassword } from 'contexts/api';

interface NavbarProps {
  firstName?: string;
  lastName?: string;
}

interface PasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const Navbar: React.FC<NavbarProps> = ({ firstName = '', lastName = '' }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [passwords, setPasswords] = useState<PasswordForm>({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  
  const LOGO_HEIGHT = 40;
 
  const getInitials = () => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setShowPasswordForm(false);
    setError(null);
    setSuccess(null);
    setPasswords({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswords(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmitPasswordChange = async () => {
    try {
      setError(null);
      
      // Basic validation
      if (passwords.newPassword !== passwords.confirmNewPassword) {
        setError("New passwords don't match");
        return;
      }
  
      if (passwords.newPassword.length < 8) {
        setError("New password must be at least 8 characters long");
        return;
      }
  
      const response = await changePassword({
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
        confirmNewPassword: passwords.confirmNewPassword
      });
  
      if (response.success) {
        setSuccess(response.message);
        setShowPasswordForm(false);
        setPasswords({
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        });
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  return (
    <>
      <AppBar
        position="static"
        color="primary"
        sx={{
          borderRadius: 0,
          '& .MuiPaper-rounded': {
            borderRadius: 0
          }
        }}
      >
        <Toolbar
          sx={{
            minHeight: '64px !important',
            py: 0
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}>
            <IconButton
              component="a"
              href="/"
              disableRipple
              sx={{
                padding: 0,
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: LOGO_HEIGHT,
                  objectFit: 'contain'
                }}
              />
            </IconButton>
            <Typography variant="h6">
              Ensemble HQ
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            {(firstName || lastName) && (
              <Tooltip title={`${firstName} ${lastName}`.trim()}>
                <Avatar 
                  sx={{
                    bgcolor: '#FFC26E',
                    width: 50,
                    height: 50,
                    fontSize: '1.3rem',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.9
                    }
                  }}
                  onClick={handleOpenModal}
                >
                  {getInitials()}
                </Avatar>
              </Tooltip>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="user-info-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Paper
          sx={{
            position: 'absolute',
            width: 400,
            bgcolor: '#252423',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#ffffff',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2,
            alignItems: 'center' 
          }}>
            <Avatar 
              sx={{
                bgcolor: '#FFC26E',
                width: 80,
                height: 80,
                fontSize: '1.5rem',
                mb: 2
              }}
            >
              {getInitials()}
            </Avatar>
            
            <Typography variant="h5" component="h2">
              {`${firstName} ${lastName}`.trim()}
            </Typography>

            <Collapse in={!showPasswordForm}>
              <Button 
                variant="contained" 
                onClick={() => setShowPasswordForm(true)}
                sx={{ 
                  mt: 2,
                  bgcolor: '#1d85b5',
                  '&:hover': {
                    bgcolor: '#1a75a0'
                  }
                }}
              >
                Change Password
              </Button>
            </Collapse>

            <Collapse in={showPasswordForm}>
              <Box sx={{ width: '100%', mt: 2 }}>
                <TextField
                  name="currentPassword"
                  type="password"
                  label="Current Password"
                  value={passwords.currentPassword}
                  onChange={handlePasswordChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="newPassword"
                  type="password"
                  label="New Password"
                  value={passwords.newPassword}
                  onChange={handlePasswordChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="confirmNewPassword"
                  type="password"
                  label="Confirm New Password"
                  value={passwords.confirmNewPassword}
                  onChange={handlePasswordChange}
                  fullWidth
                  margin="normal"
                />
                
                {error && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                )}
                
                {success && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    {success}
                  </Alert>
                )}

                <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowPasswordForm(false);
                      setError(null);
                      setSuccess(null);
                    }}
                    sx={{ 
                      color: '#ffffff',
                      borderColor: '#ffffff',
                      '&:hover': {
                        borderColor: '#1d85b5',
                        color: '#1d85b5'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmitPasswordChange}
                    sx={{ 
                      bgcolor: '#1d85b5',
                      '&:hover': {
                        bgcolor: '#1a75a0'
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default Navbar;