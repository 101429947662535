import { Role } from "types/api.types";

export const AVAILABLE_ROLES: Role[] = [
    { id: 'db238d79-cf6a-485e-b476-c31d07e23952', name: 'Sales' },
    { id: 'a2e5a51f-7d14-4e6f-9b62-1f3aecc3f73d', name: 'Installer' },
    { id: '5e33a85f-7bea-475e-8b85-b03502b0e03d', name: 'Admin' },
    { id: '1c82cfa4-4e57-4f37-8e63-677e1cb1dcf5', name: 'Internal' },
];


export enum ROLE_NAMES {
    Installer = "Installer",
    Admin = "Admin",
    Sales = "Sales",
    Internal = "Internal"
  }