import api from '../config/axios';
import { API_ROUTES } from '../config/api';
import {
  LoginCredentials,
  LoginResponse,
  RegisterEmployeeRequest,
  RegistrationResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  User,
  Account,
  InvoiceRequest,
  JsonPatchOperation,
  CreateInvoiceResponse,
  LightAccountFormData,
  PayoutDto,
  ChangePasswordRequest
} from '../types/api.types';
import axios from 'axios';

// LOGIN
export const loginUser = async (request: LoginCredentials): Promise<LoginResponse> => {
  const response = await api.post('/Users/login', request);
  const { token } = response.data;
  localStorage.setItem('token', token);
  return response.data;
};

// REGISTER EMPLOYEE
export const registerEmployee = async (credentials: RegisterEmployeeRequest): Promise<RegistrationResponse> => {
  try {
    await api.post<RegistrationResponse>(
      API_ROUTES.REGISTER_EMPLOYEE,
      credentials
    );
   
    return {
      message: 'User registered successfully',
      success: true
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        message: error.response?.data?.message || 'Registration failed',
        success: false
      };
    }
    return {
      message: 'An unexpected error occurred',
      success: false
    };
  }
};

// FETCH ALL EMPLOYEES
export const fetchEmployees = async (): Promise<User[]> => {
  const response = await api.get<User[]>(API_ROUTES.ALL_USERS);
  return response.data;
};

// UPDATE EMPLOYEE
export const updateEmployee = async (userId: string, userData: UpdateUserRequest): Promise<UpdateUserResponse> => {
  try {
    await api.put(
      API_ROUTES.UPDATE_USER,
      {
        UserId: userId,
        FirstName: userData.firstName,
        LastName: userData.lastName,
        PhoneNumber: userData.phoneNumber,
        Email: userData.email,
        Username: userData.username,
        RoleIds: userData.roles,
        PaymentStructure: userData.paymentStructure
      }
    );

    return {
      message: 'User updated successfully',
      success: true
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        message: error.response?.data?.message || 'Update failed',
        success: false
      };
    }
    return {
      message: 'An unexpected error occurred',
      success: false
    };
  }
};

// FETCH USER
export const fetchUserProfile = async (): Promise<User> => {
  const response = await api.get<User>(API_ROUTES.USER_PROFILE);
  return response.data;
};

// FETCH ACCOUNTS FOR USER
export const fetchAccounts = async (): Promise<Account[]> => {
  const response = await api.get<any[]>(API_ROUTES.ACCOUNTS);

  return response.data.map((account: any) => ({
    ...account,
    lat: account.latitude,
    lng: account.longitude,
    notes: account.notes,
    address: account.address,
  }));
};

// GET ACCOUNT PAYOUT
export const getAccountPayout = async (accountId: string): Promise<PayoutDto> => {
  try {
    const response = await api.get<PayoutDto>(`${API_ROUTES.ACCOUNTS}/payout/${accountId}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data || 'Failed to fetch payout information');
    }
    throw error;
  }
};

// FETCH ALL ACCOUNTS
export const fetchAllAccounts = async (): Promise<Account[]> => {
  const response = await api.get<any[]>(API_ROUTES.ALL_ACCOUNTS);

  return response.data.map((account: any) => ({
    ...account,
    lat: account.latitude,
    lng: account.longitude,
    notes: account.notes,
    address: account.address,
  }));
};

// ADD ACCOUNT
export const addAccount = async (account: Account): Promise<void> => {
  const transformedAccount = {
    ownerId: account.owner.id,  // Just send the ID
    customerName: account.customerName || '',
    feetOfLights: account.feetOfLights || 0,
    trackColor: account.trackColor || '',
    phoneNumber: account.phoneNumber || '',
    quote: account.quote || 0,
    acceptedAmount: account.acceptedAmount || 0,
    latitude: account.lat,
    longitude: account.lng,
    notes: account.notes || '',
    address: account.address || '',
    status: account.status || ''
  };

  await api.post(API_ROUTES.ACCOUNTS, transformedAccount);
};

// SEND INVOICE
export const createInvoice = async (request: InvoiceRequest): Promise<CreateInvoiceResponse> => {
  try {
    const response = await api.post<CreateInvoiceResponse>(API_ROUTES.CREATE_INVOICE, request);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || 'Failed to create invoice');
    }
    throw error;
  }
};

// UPDATE ACCOUNT
export const updateAccount = async (accountId: string, formData: LightAccountFormData): Promise<Account> => {
  const createPatchDocument = (formData: LightAccountFormData): JsonPatchOperation[] => {
    const operations: JsonPatchOperation[] = [];
    const fields = ['customerName', 'address', 'phoneNumber', 'status', 'materialStatus', 'email', 'description', 'feetOfLights', 'trackColor', 'acceptedPerFt', 'acceptedAmount', 'paid', 'addOns'] as const;

    fields.forEach(field => {
      if (formData[field]) {
        operations.push({
          op: 'replace',
          path: `/${field}`,
          value: formData[field]
        });
      }
    });

    return operations;
  };

  try {
    const patchDocument = createPatchDocument(formData);
    const response = await api.patch<Account>(
      `${API_ROUTES.ACCOUNTS}/${accountId}`, 
      patchDocument,
      {
        headers: {
          'Content-Type': 'application/json-patch+json'
        }
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to update account');
    }
    throw new Error('An unexpected error occurred while updating the account');
  }
};

// REMOVE ACCOUNT
export const removeAccount = async (accountId: string): Promise<void> => {
  try {
    await api.delete(`${API_ROUTES.ACCOUNTS}/${accountId}`);
    console.log(`Account ${accountId} removed successfully`);
  } catch (error) {
    console.error(`Error removing account ${accountId}:`, error);
    throw error;
  }
};

// ADD INSTALLER
export const assignInstallerToAccount = async (accountId: string, installerId: string): Promise<Account> => {
  try {
    const response = await api.post<Account>(
      `${API_ROUTES.INSTALLERS}/add-to-account`,
      { accountId, installerId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to assign installer');
    }
    throw new Error('An unexpected error occurred while assigning the installer');
  }
};

// REMOVE INSTALLER
export const removeInstallerFromAccount = async (accountId: string, installerId: string): Promise<Account> => {
  try {
    const response = await api.post<Account>(
      `${API_ROUTES.INSTALLERS}/remove-from-account`,
      { accountId, installerId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || 'Failed to remove installer');
    }
    throw new Error('An unexpected error occurred while removing the installer');
  }
};

export const changePassword = async (request: ChangePasswordRequest): Promise<{ success: boolean; message: string }> => {
  try {
    await api.put(
      `${API_ROUTES.USERS}/change-password`,
      request
    );

    return {
      success: true,
      message: 'Password changed successfully'
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        success: false,
        message: error.response?.data || 'Failed to change password'
      };
    }
    return {
      success: false,
      message: 'An unexpected error occurred'
    };
  }
};


const apiService = {
  loginUser,
  registerEmployee,
  fetchEmployees,
  updateEmployee,
  fetchUserProfile,
  fetchAccounts,
  addAccount,
  updateAccount,
  removeAccount,
  assignInstallerToAccount,
  removeInstallerFromAccount,
  changePassword
};

export default apiService;