import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, Snackbar, Alert } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InventoryIcon from '@mui/icons-material/Inventory';
import RegisterEmployeeModal from './RegisterEmployeeModal';
import ManageEmployeesModal from './ManageEmployeesModal';
import { fetchEmployees } from '../../contexts/api';
import { Account, User } from 'types/api.types';
import AssignInstallersModal from 'components/installs/AssignInstallersModal';
import ManageInventoryModal from 'components/admin/ManageInventoryModal';

interface AdminPanelProps {
  accounts: Account[];
  onAccountUpdate: (updatedAccount: Account) => void;
  readonly?: boolean;
}

const AdminPanel: React.FC<AdminPanelProps> = ({ accounts, onAccountUpdate, readonly = false }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      setIsLoading(true);
      const data = await fetchEmployees();
      setUsers(data);
    } catch (err) {
      setError('Failed to fetch employees');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignmentComplete = (updatedAccounts: Account[]) => {
    updatedAccounts.forEach(account => {
      onAccountUpdate(account);
    });
  };

  const handleUserUpdated = (updatedUser: User) => {
    setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === updatedUser.id ? updatedUser : user
      )
    );
  };

  const handleUserRegistered = () => {
    loadUsers();
  };

  const handleCloseError = () => {
    setError(null);
  };

  const paperSx = {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    border: '2px solid #ffffff',
    borderRadius: 2,
    textAlign: 'center',
    minHeight: '150px',
  };

  const iconSx = {
    fontSize: 40,
    color: '#ffffff',
    marginBottom: 1,
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: 2,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          textAlign: 'center',
          fontSize: { xs: '1.5rem', sm: '2.125rem' },
        }}
      >
        Admin Panel
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Paper elevation={3} sx={{ ...paperSx, width: { xs: '100%', sm: 'calc(50% - 16px)', lg: 'calc(25% - 16px)' } }}>
          <GroupAddIcon sx={iconSx} />
          <Typography variant="h6" sx={{ fontSize: '1rem', marginBottom: 1 }}>
            Register New Employees
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '0.875rem' }}
            onClick={() => setIsRegisterModalOpen(true)}
            disabled={readonly}
          >
            Register
          </Button>
        </Paper>

        <Paper elevation={3} sx={{ ...paperSx, width: { xs: '100%', sm: 'calc(50% - 16px)', lg: 'calc(25% - 16px)' } }}>
          <PeopleIcon sx={iconSx} />
          <Typography variant="h6" sx={{ fontSize: '1rem', marginBottom: 1 }}>
            Manage Employees
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '0.875rem' }}
            onClick={() => setIsManageModalOpen(true)}
            disabled={readonly}
          >
            Manage
          </Button>
        </Paper>

        {/* Other Papers remain the same */}
        <Paper elevation={3} sx={{ ...paperSx, width: { xs: '100%', sm: 'calc(50% - 16px)', lg: 'calc(25% - 16px)' } }}>
          <AssignmentIndIcon sx={iconSx} />
          <Typography variant="h6" sx={{ fontSize: '1rem', marginBottom: 1 }}>
            Assign Installers
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '0.875rem' }}
            onClick={() => setIsAssignModalOpen(true)}
            disabled={readonly}
          >
            Assign
          </Button>
        </Paper>

        <Paper elevation={3} sx={{ ...paperSx, width: { xs: '100%', sm: 'calc(50% - 16px)', lg: 'calc(25% - 16px)' } }}>
          <InventoryIcon sx={iconSx} />
          <Typography variant="h6" sx={{ fontSize: '1rem', marginBottom: 1 }}>
            Manage Inventory
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '0.875rem' }}
            onClick={() => setIsInventoryModalOpen(true)}
          >
            Manage
          </Button>

        </Paper>
      </Box>

      <RegisterEmployeeModal
        open={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        onUserRegistered={handleUserRegistered}
      />

      <ManageEmployeesModal
        open={isManageModalOpen}
        onClose={() => setIsManageModalOpen(false)}
        users={users}
        isLoading={isLoading}
        onUserUpdated={handleUserUpdated}
      />

      <AssignInstallersModal
        open={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        accounts={accounts.filter(account => account.status === 'Accepted')}
        installers={users.filter(user =>
          user.roles?.some(role => role.name === 'Installer')
        )}
        onAssignmentComplete={handleAssignmentComplete}
      />

      <ManageInventoryModal
        open={isInventoryModalOpen}
        onClose={() => setIsInventoryModalOpen(false)}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminPanel;