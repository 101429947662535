import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    InputAdornment,
    IconButton
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { Account } from 'types/api.types';

interface InvoiceLineItem {
    description: string;
    amount: number;
    quantity: number;
}

interface InvoiceConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (lineItems: InvoiceLineItem[]) => Promise<void>;
    account: Account | null;
    isLoading: boolean;
    error: string | null;
}

const InvoiceConfirmationModal: React.FC<InvoiceConfirmationModalProps> = ({
    open,
    onClose,
    onConfirm,
    account,
    isLoading,
    error
}) => {
    const [lineItems, setLineItems] = useState<InvoiceLineItem[]>([]);

    useEffect(() => {
        if (account && open && lineItems.length === 0) {
            setLineItems([
                {
                    description: `${account.feetOfLights}ft ${account.trackColor} track installation`,
                    amount: account.acceptedAmount || account.quote || 0,
                    quantity: 1
                }
            ]);
        }
    }, [account, open, lineItems.length]);

    useEffect(() => {
        if (!open) {
            setLineItems([]);
        }
    }, [open]);

    const handleAddLineItem = () => {
        setLineItems([...lineItems, { description: '', amount: 0, quantity: 1 }]);
    };

    const handleLineItemChange = (index: number, field: keyof InvoiceLineItem, value: string | number) => {
        const newLineItems = [...lineItems];
        newLineItems[index] = { ...newLineItems[index], [field]: value };
        setLineItems(newLineItems);
    };

    const handleRemoveLineItem = (index: number) => {
        setLineItems(lineItems.filter((_, i) => i !== index));
    };

    const total = lineItems.reduce((sum, item) => sum + (item.amount * item.quantity), 0);

    const textFieldStyle = {
        '& .MuiInputLabel-root': {
            position: 'relative',
            transform: 'none',
            marginBottom: 1,
            color: '#1d85b5',
            fontWeight: 500,
            background: 'transparent',
            '&.Mui-focused': {
                color: '#1d85b5'
            }
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            background: 'transparent'
        },
        '& .MuiOutlinedInput-root': {
            color: '#ffffff',
            '& fieldset': {
                borderColor: 'rgba(29, 133, 181, 0.5)',
            },
            '&:hover fieldset': {
                borderColor: '#1d85b5',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1d85b5',
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    bgcolor: '#252423',
                    color: '#ffffff',
                    '& .MuiDialogTitle-root': {
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                        pb: 2
                    },
                    '& .MuiDialogActions-root': {
                        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                        px: 3,
                        py: 2
                    }
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 500 }}>
                    Confirm Invoice Creation
                </Typography>
                {account && (
                    <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)', mt: 0.5 }}>
                        for {account.customerName}
                    </Typography>
                )}
            </DialogTitle>
            <DialogContent sx={{ px: 3, py: 2 }}>
                {lineItems.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            mb: 2,
                            p: 2.5,
                            bgcolor: 'rgba(29, 133, 181, 0.1)',  // Light tint of primary color
                            borderRadius: 1,
                            border: '1px solid rgba(29, 133, 181, 0.2)',
                            position: 'relative'
                        }}
                    >
                        <TextField
                            fullWidth
                            label="Description"
                            value={item.description}
                            onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
                            variant="outlined"
                            sx={{
                                mb: 2,
                                ...textFieldStyle
                            }}
                        />

                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-end' }}> {/* Changed to flex-end */}
                            <TextField
                                type="number"
                                label="Amount"
                                value={item.amount === 0 ? '' : item.amount}
                                onChange={(e) => {
                                    const value = e.target.value === '' ? 0 : parseFloat(e.target.value);
                                    handleLineItemChange(index, 'amount', value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                            $
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        color: '#ffffff',
                                        '& input': { pl: 0.5 }
                                    }
                                }}
                                sx={{
                                    flexGrow: 1,
                                    ...textFieldStyle
                                }}
                            />
                            <TextField
                                type="number"
                                label="Quantity"
                                value={item.quantity}  // Removed the ternary
                                onChange={(e) => {
                                    const value = e.target.value === '' ? 1 : parseInt(e.target.value);
                                    handleLineItemChange(index, 'quantity', Math.max(1, value)); // Ensure minimum of 1
                                }}
                                sx={{
                                    width: '100px',
                                    ...textFieldStyle
                                }}
                            />
                            {index > 0 && (
                                <IconButton
                                    onClick={() => handleRemoveLineItem(index)}
                                    sx={{
                                        color: 'rgba(255, 255, 255, 0.5)',
                                        '&:hover': {
                                            color: '#ff4444',
                                            bgcolor: 'rgba(255, 68, 68, 0.1)'
                                        },
                                        height: '56px' // Match height of text fields
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            )}
                        </Box>
                        <Typography sx={{ mt: 2, textAlign: 'right', color: 'rgba(255, 255, 255, 0.7)' }}>
                            Subtotal: <span style={{ color: '#ffffff', fontWeight: 500 }}>${(item.amount * item.quantity).toFixed(2)}</span>
                        </Typography>
                    </Box>
                ))}

                <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                    <Button
                        startIcon={<Add />}
                        onClick={handleAddLineItem}
                        variant="outlined"
                        sx={{
                            borderColor: '#1d85b5',
                            color: '#1d85b5',
                            '&:hover': {
                                borderColor: '#1d85b5',
                                bgcolor: 'rgba(29, 133, 181, 0.1)'
                            }
                        }}
                    >
                        Add Another Line Item
                    </Button>
                </Box>

                <Box sx={{
                    mt: 3,
                    p: 2.5,
                    bgcolor: '#1d85b5',
                    borderRadius: 1,
                    boxShadow: '0 2px 8px rgba(29, 133, 181, 0.2)'
                }}>
                    <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 500 }}>
                        Total: ${total.toFixed(2)}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={isLoading}
                    sx={{
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&:hover': {
                            bgcolor: 'rgba(255, 255, 255, 0.05)'
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => onConfirm(lineItems)}
                    variant="contained"
                    disabled={isLoading || lineItems.length === 0 || !lineItems.every(item => item.description && item.amount > 0)}
                    sx={{
                        bgcolor: '#1d85b5',
                        '&:hover': {
                            bgcolor: '#1a75a0'
                        }
                    }}
                >
                    {isLoading ? 'Creating...' : 'Create Invoice'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoiceConfirmationModal;