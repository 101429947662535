import React, { useCallback, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Divider,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import {
  Money,
  AccessTime,
  Person,
  Place,
  Close as CloseIcon,
  AttachMoney as MoneyIcon
} from '@mui/icons-material';
import { Draggable } from '@hello-pangea/dnd';
import { AccountCardProps, PayoutDto } from 'types/api.types';
import { getAccountPayout } from 'contexts/api';
import { ROLE_NAMES } from 'constants/roles';

interface ExtendedAccountCardProps extends AccountCardProps {
  onDelete?: (accountId: string) => Promise<void>;
  readonly?: boolean;
  isDragDisabled?: boolean;
}

export const AccountCard: React.FC<ExtendedAccountCardProps> = ({
  account,
  index,
  onClick,
  formatCurrency,
  onDelete,
  readonly = false,
  isDragDisabled = false
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [salesDialogOpen, setSalesDialogOpen] = useState(false);
  const [payoutData, setPayoutData] = useState<PayoutDto | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const fetchPayoutData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getAccountPayout(account.id);
      setPayoutData(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch payout data');
    } finally {
      setLoading(false);
    }
  }, [account.id]);

  const handleSalesClick = async (e: React.MouseEvent) => {
    if (readonly) return;
    e.stopPropagation();
    setSalesDialogOpen(true);
    if (!payoutData) {
      await fetchPayoutData();
    }
  };

  const handleConfirmDelete = async () => {
    if (readonly) return;
    if (onDelete) {
      await onDelete(account.id);
    }
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Draggable draggableId={account.id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => onClick(account)}
            sx={{
              mb: 1,
              cursor: readonly ? 'default' : 'pointer',
              backgroundColor: '#323130 !important',
              color: '#ffffff',
              position: 'relative',
              '&:hover': {
                boxShadow: readonly ? 'none' : 6,
                transform: snapshot.isDragging ? 'none' : (readonly ? 'none' : 'translateY(-2px)'),
                transition: 'all 0.2s',
                '& .cardButton': {
                  opacity: 1,
                }
              },
              ...(snapshot.isDragging && {
                boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
              })
            }}
          >
            <Box sx={{ position: 'absolute', right: 8, top: 8, display: 'flex', gap: 1 }}>
              <IconButton
                className="cardButton"
                onClick={handleSalesClick}
                disabled={readonly}
                sx={{
                  color: readonly ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.7)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  padding: '4px',
                  '&:hover': {
                    color: readonly ? 'rgba(255, 255, 255, 0.3)' : '#4CAF50',
                    backgroundColor: readonly ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                <MoneyIcon fontSize="small" />
              </IconButton>
              {onDelete && (
                <IconButton
                  className="cardButton"
                  onClick={handleDeleteClick}
                  disabled={readonly}
                  sx={{
                    color: readonly ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.7)',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    padding: '4px',
                    '&:hover': {
                      color: readonly ? 'rgba(255, 255, 255, 0.3)' : '#4CAF50',
                      backgroundColor: readonly ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
                    }
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            <CardContent>
              <Typography variant="h6" gutterBottom>
                {account.customerName || 'Unnamed Account'}
              </Typography>

              <Box sx={{ mb: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip
                  size="small"
                  label={`${formatCurrency(account.acceptedAmount || account.quote || 0)}`}
                  color="success"
                />
                {account.paymentStatus && (
                  <Chip
                    size="small"
                    label={`Customer ${account.paymentStatus || 0}`}
                    color="primary"
                  />
                )}
                {account.materialStatus && (
                  <Chip
                    size="small"
                    label={`Materials ${account.materialStatus || 0}`}
                    color="secondary"
                  />
                )}
              </Box>
              <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Money fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                  <Typography variant="body2">
                    {`${account.feetOfLights || ''} ft`}
                  </Typography>
                </Box>
                {account.address && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Place fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography variant="body2" noWrap>
                      {account.address}
                    </Typography>
                  </Box>
                )}
                {account.owner && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Person fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography variant="body2" noWrap>
                      {`${account.owner.firstName || ''} ${account.owner.lastName || ''}`}
                    </Typography>
                    <Chip
                      size="small"
                      label={account.employeePaymentStatus.toUpperCase()}
                      color={account.employeePaymentStatus.toLowerCase() === 'paid' ? 'success' : 'default'}
                    />
                  </Box>
                )}
                {account.installDate && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccessTime fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography variant="body2">
                      {new Date(account.installDate).toLocaleDateString()}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </Draggable>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#323130',
            color: '#ffffff',
          }
        }}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the account for {account.customerName}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{
              backgroundColor: '#ff4444',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ff0000',
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sales Information Dialog */}
      <Dialog
        open={salesDialogOpen}
        onClose={() => setSalesDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#323130',
            color: '#ffffff',
            minWidth: '350px'
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Sales Information
            <IconButton
              onClick={() => setSalesDialogOpen(false)}
              sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : payoutData ? (
            <Stack spacing={3}>
              {/* Total Amount Section */}
              <Box sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                p: 2,
                borderRadius: 1,
                textAlign: 'center'
              }}>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Total Amount
                </Typography>
                <Typography variant="h4" sx={{ color: '#4CAF50' }}>
                  {formatCurrency(account.acceptedAmount || account.quote || 0)}
                </Typography>
              </Box>

              {/* Owner Section */}
              <Box sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                p: 2,
                borderRadius: 1
              }}>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Owner Payout
                </Typography>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 1
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Person sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                    <Typography>
                      {payoutData.owner.firstName} {payoutData.owner.lastName}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: '#4CAF50' }}>
                    {formatCurrency(payoutData.ownerPayout)}
                  </Typography>
                </Box>
              </Box>

              {/* Installers Section */}
              {payoutData.userPayouts.filter(p => p.role.name === ROLE_NAMES.Installer).length > 0 && (
                <Box sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  p: 2,
                  borderRadius: 1
                }}>
                  <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                    Installers
                  </Typography>
                  <Stack spacing={2} sx={{ mt: 1 }}>
                    {payoutData.userPayouts
                      .filter(p => p.role.name === ROLE_NAMES.Installer)
                      .map((payout) => (
                        <Box
                          key={payout.user.id}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            py: 1,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Person sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                            <Typography>
                              {payout.user.firstName} {payout.user.lastName}
                            </Typography>
                          </Box>
                          <Typography sx={{ color: '#4CAF50' }}>
                            {formatCurrency(payout.payout)}
                          </Typography>
                        </Box>
                      ))}
                  </Stack>
                </Box>
              )}

              {/* Admins Section */}
              {payoutData.userPayouts.filter(p => p.role.name === ROLE_NAMES.Admin).length > 0 && (
                <Box sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  p: 2,
                  borderRadius: 1
                }}>
                  <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                    Admins
                  </Typography>
                  <Stack spacing={2} sx={{ mt: 1 }}>
                    {payoutData.userPayouts
                      .filter(p => p.role.name === ROLE_NAMES.Admin)
                      .map((payout) => (
                        <Box
                          key={payout.user.id}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            py: 1,
                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Person sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                            <Typography>
                              {payout.user.firstName} {payout.user.lastName}
                            </Typography>
                          </Box>
                          <Typography sx={{ color: '#4CAF50' }}>
                            {formatCurrency(payout.payout)}
                          </Typography>
                        </Box>
                      ))}
                  </Stack>
                </Box>
              )}

              {/* Company Profit Section */}
              <Box sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                p: 2,
                borderRadius: 1,
                textAlign: 'center'
              }}>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Company Profit
                </Typography>
                <Typography variant="h5" sx={{ color: '#4CAF50' }}>
                  {formatCurrency(payoutData.companyProfit)}
                </Typography>
              </Box>
            </Stack>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};
