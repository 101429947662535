// src/config/api.ts
import { environment } from './environment';

export const API_ROUTES = {
    LOGIN: `${environment.API_URL}/Users/login`,
    REGISTER_EMPLOYEE: `${environment.API_URL}/Users/register-employee`,
    ALL_USERS: `${environment.API_URL}/Users/all`,
    UPDATE_USER: `${environment.API_URL}/Users`,
    USER_PROFILE: `${environment.API_URL}/Users/`,
    USERS: `${environment.API_URL}/Users`,
    ACCOUNTS: `${environment.API_URL}/Accounts`,
    ALL_ACCOUNTS: `${environment.API_URL}/Accounts/all`,
    INSTALLERS: `${environment.API_URL}/Installers`,
    CREATE_INVOICE: `${environment.API_URL}/Invoices`,
    INVENTORY: '/Inventory',
    INVENTORY_MOVEMENT: '/Inventory/movement',
    REMOVE_ACCOUNT: (pinId: string) => `${environment.API_URL}/Accounts/${pinId}`,
  };
  