// src/config/environment.ts
interface Environment {
  API_URL: string;
  isDevelopment: boolean;
}

const PROD_URL = 'https://api.ensemble-hq.com';
const DEV_URL = 'https://localhost:7239';

const isDev = window.location.hostname === 'localhost' || 
              window.location.hostname === '127.0.0.1';

export const environment: Environment = {
  API_URL: isDev ? DEV_URL : PROD_URL,
  isDevelopment: isDev
};