import React, { useState } from 'react';
import { Box, Paper, Stack, Switch, FormControlLabel, Button } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { AccountCard } from './AccountCard';
import { ColumnHeader } from './ColumnHeader';
import { Account, ViewProps } from 'types/api.types';
import AccountModal from 'components/sales/AccountModal';

const ITEMS_PER_PAGE = 10;

export const DesktopView: React.FC<ViewProps> = ({
    statusColumns,
    groupedAccounts,
    getColumnStats,
    formatCurrency,
    handleDragEnd,
    handleEditAccount,
    modalOpen,
    setModalOpen,
    currentAccount,
    accountFormData,
    setAccountFormData,
    handleSave,
    handleDeleteAccount,
    readonly = false
}) => {
    const [hideCompleted, setHideCompleted] = useState(false);
    const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE);

    const filteredGroupedAccounts = statusColumns.reduce((acc, status) => {
        if (hideCompleted && status === 'Complete') {
            acc[status] = [];
        } else {
            acc[status] = (groupedAccounts[status] || []).slice(0, visibleItems);
        }
        return acc;
    }, {} as Record<string, Account[]>);

    const handleDragEndWithReadonly = (result: any) => {
        if (!readonly) {
            handleDragEnd(result);
        }
    };

    return (
        <>
            <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hideCompleted}
                            onChange={() => setHideCompleted(!hideCompleted)}
                            color="primary"
                        />
                    }
                    label="Hide Completed"
                />
            </Box>

            <DragDropContext onDragEnd={handleDragEndWithReadonly}>
                <Box sx={{ p: 2, height: 'calc(100vh - 112px)', overflow: 'hidden' }}>
                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        height: '100%',
                        overflow: 'auto',
                        pb: 1
                    }}>
                        {statusColumns.map(status => {
                            const columnAccounts = filteredGroupedAccounts[status] || [];
                            const stats = getColumnStats(columnAccounts);

                            return (
                                <Paper
                                    key={status}
                                    sx={{
                                        width: 300,
                                        minWidth: 300,
                                        height: 'fit-content',
                                        maxHeight: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        bgcolor: '#114D69',
                                        p: '0 !important',
                                        textAlign: 'left !important',
                                        '& > .MuiBox-root:first-of-type': {
                                            borderTopLeftRadius: 'inherit',
                                            borderTopRightRadius: 'inherit'
                                        }
                                    }}
                                    elevation={2}
                                >
                                    <ColumnHeader
                                        status={status}
                                        stats={stats}
                                        formatCurrency={formatCurrency}
                                    />

                                    <Droppable droppableId={status} isDropDisabled={readonly}>
                                        {(provided, snapshot) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                sx={{
                                                    overflow: 'auto',
                                                    flexGrow: 1,
                                                    minHeight: 80,
                                                    bgcolor: snapshot.isDraggingOver ? 'action.hover' : 'inherit',
                                                    transition: 'background-color 0.2s ease'
                                                }}
                                            >
                                                <Stack
                                                    spacing={0.5}
                                                    sx={{
                                                        p: 0.5,
                                                        '& > div:not([data-rbd-dragging-draggable-id])': {
                                                            width: '100%'
                                                        }
                                                    }}
                                                >
                                                    {columnAccounts.map((account, index) => (
                                                        <AccountCard
                                                            key={account.id}
                                                            account={account}
                                                            index={index}
                                                            onClick={handleEditAccount}
                                                            formatCurrency={formatCurrency}
                                                            onDelete={handleDeleteAccount}
                                                            readonly={readonly}
                                                            isDragDisabled={readonly}
                                                        />
                                                    ))}
                                                </Stack>
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </Paper>
                            );
                        })}
                    </Box>
                </Box>
            </DragDropContext>

            {Object.values(groupedAccounts).some(group => group.length > visibleItems) && (
                <Box sx={{ position: 'fixed', bottom: 16, left: '50%', transform: 'translateX(-50%)' }}>
                    <Button
                        variant="contained"
                        onClick={() => setVisibleItems(prev => prev + ITEMS_PER_PAGE)}
                    >
                        Load More
                    </Button>
                </Box>
            )}

            {modalOpen && (
                <AccountModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSave}
                    formData={accountFormData}
                    setFormData={setAccountFormData}
                    isNewAccount={false}
                />
            )}
        </>
    );
};