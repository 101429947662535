import api from '../config/axios';
import { API_ROUTES } from '../config/api';
import {
  InventoryMovement,
  InventoryQuantities,
  InventorySummary,
  InventoryAssignmentSummary
} from '../types/api.types';
import axios from 'axios';

// PROCESSS INVENTORY MOVEMENT
export const processInventoryMovement = async (movement: InventoryMovement): Promise<void> => {
  try {
    await api.post(API_ROUTES.INVENTORY_MOVEMENT, movement);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || 'Failed to process inventory movement');
    }
    throw error;
  }
};

// GET AVAILABLE INVENTORY
export const getAvailableInventory = async (inventoryId: string): Promise<InventoryQuantities> => {
  try {
    const response = await api.get<InventoryQuantities>(`${API_ROUTES.INVENTORY}/${inventoryId}/available`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || 'Failed to fetch available inventory');
    }
    throw error;
  }
};

// GET INVENTORY SUMMARY
export const getInventorySummary = async (inventoryId: string): Promise<InventorySummary> => {
  try {
    const response = await api.get<InventorySummary>(`${API_ROUTES.INVENTORY}/${inventoryId}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || 'Failed to fetch inventory summary');
    }
    throw error;
  }
};

// GET ASSIGNED INVENTORY
export const getAccountAssignments = async (accountId: string): Promise<InventoryAssignmentSummary[]> => {
  try {
    const response = await api.get<InventoryAssignmentSummary[]>(
      `${API_ROUTES.INVENTORY}/account/${accountId}/assignments`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || 'Failed to fetch account assignments');
    }
    throw error;
  }
};

// VALIDATE IF INVENTORY CAN BE ASSIGNED
export const canAssignInventory = async (
  inventoryId: string, 
  requested: InventoryQuantities
): Promise<boolean> => {
  try {
    const response = await api.post<boolean>(
      `${API_ROUTES.INVENTORY}/${inventoryId}/can-assign`,
      requested
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.detail || 'Failed to check inventory availability');
    }
    throw error;
  }
};

const inventoryService = {
  processInventoryMovement,
  getAvailableInventory,
  getInventorySummary,
  getAccountAssignments,
  canAssignInventory
};

export default inventoryService;